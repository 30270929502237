import React, { Suspense } from 'react'
import { createRoot } from 'react-dom/client'
import './index.css'
import * as serviceWorker from './serviceWorker'
import { isProdNodeEnv } from 'constants/Utils.constants'
import { FullPageLoader } from 'components/Loaders'
import componentLoader from 'features/lazy-loader/ComponentLoader'
import { ErrorBoundary } from 'react-error-boundary'
import GenericErrorFallback from 'components/ErrorBoundary/GenericErrorFallback'
const App = componentLoader(() => import('./App'))

const ERROR_FALLBACK_TEXT =
  'Looks like the application failed to load. This is most likely due to a slow internet connection. Please reload the page.'

const container = document.getElementById('root')!
const root = createRoot(container)
root.render(
  <React.StrictMode>
    <ErrorBoundary fallback={<GenericErrorFallback displayText={ERROR_FALLBACK_TEXT} />}>
      <Suspense fallback={<FullPageLoader data-testid="page-loader" />}>
        <App />
      </Suspense>
    </ErrorBoundary>
  </React.StrictMode>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
if (isProdNodeEnv) {
  serviceWorker.register()
} else {
  serviceWorker.unregister()
}
